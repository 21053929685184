import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
      <a href="https://www.instagram.com/alidevuz" className="home__social-icon" target='blank'>
        <i className="uil uil-instagram"></i>
      </a>

      <a href="https://www.t.me/alidev1" className="home__social-icon" target='blank'>
        <i className="uil uil-telegram"></i>
      </a>

      <a href="https://www.github.com/aliwebdev" className="home__social-icon" target='blank'>
        <i className="uil uil-github-alt"></i>
      </a>
    </div>
  )
}

export default Social