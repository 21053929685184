import React from "react";

const Design = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">UX/UI</h3>
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Veb dizayn</h3>
              <span className="skills__level">O'rta</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Verstka</h3>
              <span className="skills__level">Pro</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Figma</h3>
              <span className="skills__level">O'rta</span>
            </div>
          </div>
        </div>
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Dizayn</h3>
              <span className="skills__level">O'rta</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
